/* auto-height */

ids = [];

function scrollToAnchor(href) {
	var hrefWithoutHash = href.replace('#', '');
	var anchorSection = $("a[id='"+ hrefWithoutHash +"']");
	var heightToAnchor = $(anchorSection).offset().top + 'px';
	$('html, body').animate({
		scrollTop: heightToAnchor
	},
		1100,
		'easeInOutExpo'
	);
	setTimeout(function() {window.location.hash = href}, 500);
	event.preventDefault();
}

function scrollToTop () {
	$('body, html').animate({
        scrollTop: 0
    }, 400);
    return false;
}

function blockHeight(ids) {
  $.each(ids, function(i, id) {
    var h = 0;
    var $autoHeight = $('.height[data-height="' + id + '"]');
    $autoHeight.each(function() {
      if ($(this).outerHeight() > h) {
        h = $(this).outerHeight();
      }
    });
    $autoHeight.outerHeight(h).addClass('heighted');
  });
}

var freezeVeiwPort = function(e) {
  e.preventDefault();
};

function stopBodyScrolling (bool) {
  if (bool === true) {
    document.body.addEventListener("touchmove", freezeVeiwPort, false);
  } else {
    document.body.removeEventListener("touchmove", freezeVeiwPort, false);
  }
}

/* inner pages fade by scroll */

function fadeOneByOne(e, c) {
  if ($(e).next(c).length > 0) {
    $(e).next(c).fadeTo(500, 1, function() {
      fadeOneByOne($(e).next(c), c);
    });
  }
}

/* index page carousel */

function carouselInit() {
  $('.b-carousel')
    .jcarousel()
    .touchwipe({
      wipeLeft: function() {
        $('.b-carousel').jcarousel('scroll', '+=1');
      },
      wipeRight: function() {
        $('.b-carousel').jcarousel('scroll', '-=1');
      },
      min_move_x: 20,
      min_move_y: 20,
      preventDefaultEvents: false
    });

    $('.b-carousel__prev')
    .on('jcarouselcontrol:active', function() {
      $(this).removeClass('disabled');
    })
    .on('jcarouselcontrol:inactive', function() {
      $(this).addClass('disabled');
    })
    .jcarouselControl({
      target: '-=1'
    });

    $('.b-carousel__next')
    .on('jcarouselcontrol:active', function() {
      $(this).removeClass('disabled');
    })
    .on('jcarouselcontrol:inactive', function() {
      $(this).addClass('disabled');
    })
    .jcarouselControl({
      target: '+=1'
    });
}

/**
 * @param {string} popupId
 * @param {string} errorMessage
 * */
function showMessageBox(popupId, errorMessage) {
  $('body').addClass('fixed-scroll');
  $('.b-popup').fadeOut(300);
  if ($(this).hasClass('js-popup-nomobile') && $('.hide-mobile').css('display') == 'none') {
    var target = $(this).attr('href').split('#')[1];
    $.scrollTo($('[name="' + target + '"]'), 1000, {
        onAfter: function() {
            window.location.hash = target;
        }
    });
    return false;
  } else {
    var id = popupId;

    var errorNode = $('#' + id + ' div.b-popup__error_message');
    if (errorNode.length) {
      errorNode.empty();
      if (errorMessage) {
        errorNode.append(errorMessage);
      }
    }
    var $popup = $('.b-popup#' + id);
    var closeHandler = $popup.data('close-handler');
    $('#fader').data('close-handler', closeHandler);

    $('#fader').fadeIn(300);
    $('.b-popup#' + id).css('display', 'flex');
    $('.b-popup#' + id).addClass('b-popup-flex');
    
    setTimeout(function() {
      var $popup = $('.b-popup#' + id);
      if ($popup.outerHeight() + 200 > $(window).height()) {
        $popup.addClass('fixed').css({
          // top: $(window).scrollTop() + 100
        });
      }
    }, 0);
    return false;
  }
}

var showRealPlayer = null;

$(document).ready(function () {
	$(".nav__link").on('click', function() {
		var hrefAtrribute = $(this).attr('href');
		scrollToAnchor(hrefAtrribute);

		document.documentElement.clientWidth <= 1024 ? (
			$('.b-head__menu').fadeToggle(300),
			$('.b-head').removeClass('active')
		)
    : null;
  });

	var arrowToTop = $('.scroll-to-top');
	var arrowToTopClass = arrowToTop.attr('class');
	var isClassChanged = false;
	
  arrowToTop.on('click', function() {
		scrollToTop();
	});
	
	$(window).scroll(function () {
		var topPosition = $(this).scrollTop();

		if (topPosition > 100) {
			arrowToTop.addClass(arrowToTopClass + '--visible');
			isClassChanged = true;
			arrowToTop.removeClass(arrowToTopClass + '--hide');
		} else {
			arrowToTop.removeClass(arrowToTopClass + '--visible');
		}

		if (topPosition < 100 && isClassChanged === true) {
			arrowToTop.addClass(arrowToTopClass + '--hide');
		}
			 
	  if (topPosition > 120) {
		$('body:not(.inner)').addClass('scrolled');
		$('body:not(.inner) .b-head__logo').stop().fadeTo(0, 1);
	  } else {
		$('body:not(.inner)').removeClass('scrolled');
		$('body:not(.inner) .b-head__logo').stop().fadeTo(0, 0);
	  }
	
	  $('.js-fade-container:in-viewport(-200)').find('.js-fade:first').fadeTo(500, 1, function () {
		fadeOneByOne(this, '.js-fade');
	  });
	
	  $('.js-video:in-viewport(-200)').find('video').each(function () {
		this.play();
	  });
	});

  $('.height').each(function () {
    var id = $(this).attr('data-height');
    if ($.inArray(id, ids) == -1) {
      ids[ids.length] = id;
    }
  });
  blockHeight(ids);

  carouselInit();

  $('.b-professionals__list').jcarousel()
    .touchwipe({
      wipeLeft: function() {
          $('.b-professionals__list').jcarousel('scroll', '+=1');
      },
      wipeRight: function() {
          $('.b-professionals__list').jcarousel('scroll', '-=1');
      },
      min_move_x: 20,
      min_move_y: 20,
      preventDefaultEvents: false
      });

    $('.js-popup').click(function() {        
      var id = $(this).attr('data-popup');
      showMessageBox(id, null);
      stopBodyScrolling(true);
    });

    $('.b-popup__link--fb').click(function() {
      stopBodyScrolling(false);
      $('body').removeClass('fixed-scroll');
    });

    $('#fader, .js-popup-close').click(function() {
      stopBodyScrolling(false);
      $('body').removeClass('fixed-scroll');
      var $closer = $(this);
      $('#fader, .b-popup').fadeOut(300);

      if (!$closer.is('#fader')) {
        if (!$closer.is('.b-popup')) $closer = $closer.parents('.b-popup').first();
        if (!$closer.is('.b-popup')) return false;
      }
      var closeHandler = $closer.data('close-handler');
      if (!closeHandler) return false;
      if (window[closeHandler]) window[closeHandler]();
      return false;
    });
    
    $('.b-head__menutrigger, .b-head__menu__close').click(function () {
      $('.b-head__menu').fadeToggle(300);
      $('.b-head').toggleClass('active');
    });
    
    $('.b-professionals__list li').hover(function () {
      $(this).find('.b-professionals__text').show();
    }, function () {
      $(this).find('.b-professionals__text').hide();
    });
    
    $('.b-casehead').css({
      'max-height': $(window).height() - 100
    });

    $('.js-fade-container:in-viewport(-200)').find('.js-fade:first').fadeTo(500, 1, function () {
      fadeOneByOne(this, '.js-fade');
    });

  $('a[href^="/#"]:not(.js-popup-nomobile)').each(function () {
    var target = $(this).attr('href').split('#')[1];
    $(this).click(function () {
      $('.b-head__nav a[href^="/#"]').removeClass('active');
      if ($('.hide-mobile').css('display') == 'none') {
        $('.b-head__menu__close').trigger('click');
      }
      $(this).addClass('active');
      $.scrollTo($('[id="' + target + '"]'), 1000, {
        onAfter: function () {
          window.location.hash = target;
        }
      });
      return false;
    });
  });

    $('.js-video:in-viewport(-200)').find('video').each(function() {
      this.play();
    });

    $('#popupFeedbackThanks').click(function() {
      $('#fader, .b-popup').fadeOut(300);
    });

    $(function() {
      var $iframe = $('.showreal-video')[0];
      showRealPlayer = $f($iframe);
    });
});

function onShowRealClose() {
  if (!showRealPlayer) return;
  showRealPlayer.api('pause');
}

$(window).resize(function() {
  blockHeight(ids);
  $('.b-head__menu').attr('style', '');
  $('.b-head').removeClass('active');
  $('.b-casehead').css({
    'max-height': $(window).height() - 100
  });
});

$(window).load(function() {
  blockHeight(ids);
  carouselInit();
});
