(function () {

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // ValidationResult

  /** @class */
  function ValidationResult() {
    /** @private */
    this.errorMessage = "";
  }

  /**
   * @param {string} errorMessage
   * @return {ValidationResult}
   */
  ValidationResult.error = function (errorMessage) {
    var res = new ValidationResult();
    res.errorMessage = errorMessage;
    return res;
  };

  /**
   * @return {ValidationResult}
   */
  ValidationResult.success = function () {
    return new ValidationResult();
  };

  /**
   * @return {boolean}
   * */
  ValidationResult.prototype.hasError = function () {
    return Boolean(this.errorMessage);
  };

  /**
   * @return {string}
   */
  ValidationResult.prototype.getErrorMessage = function () {
    return this.errorMessage;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Validator

  /**
   * @typedef {function(string): ValidationResult} ValidateDelegate
   * @typedef {{field: jQuery, validator: ValidateDelegate, label: jQuery, result: ValidationResult, focused: boolean, changed: boolean}} FieldRec
   */

  /** @class */
  function Validator() {
    /**
     * @private
     * @type {Array<FieldRec>}
     **/
    this.fields = [];

    /** @private */
    this.errorClass = 'error';

    /** @private */
    this.shakeClass = 'b-field__shake';

    /** @private */
    this.labelClass = 'b-field__hint';

    /** @private */
    this.visibleLabelClass = 'b-field__hint__visible';
  }

  /**
   * @param {jQuery} field
   * @param {ValidateDelegate} validator
   */
  Validator.prototype.addField = function (field, validator) {
    if (!field.length) return;

    /** @type {object} */
    var fieldRec = {
      field: field,
      validator: validator,
      label: null,
      result: ValidationResult.success(),
      focused: false,
      changed: false
    };

    var that = this;

    field.focusin(function () {
      fieldRec.focused = true;
      that.updateFieldLabel(fieldRec);
    });

    field.focusout(function () {
      fieldRec.focused = false;
      if (fieldRec.changed) {
        var value = field.val().toString().trim();
        fieldRec.result = validator(value);
      }
      if (fieldRec.result.hasError()) {
        field.addClass(that.errorClass);
      } else {
        field.removeClass(that.errorClass);
      }
      that.updateFieldLabel(fieldRec);
    });
    field.on('input', function () {
      fieldRec.changed = true;
    });

    this.fields.push(fieldRec);
  };

  /**
   * Validate all registered fields
   *
   * @return {boolean} true if all fields is valid
   * */
  Validator.prototype.validate = function () {
    var valid = true;
    var that = this;

    /** @type {jQuery} */
    var firstInvalidField = null;

    /** @type {FieldRec} */
    var rec;
    var i;

    for (i = 0; i < this.fields.length; i++) {
      rec = this.fields[i];
      rec.field.removeClass(this.shakeClass);
    }

    for (i = 0; i < this.fields.length; i++) {
      rec = this.fields[i];
      var value = rec.field.val().toString().trim();
      rec.result = rec.validator(value);
      if (!rec.result.hasError()) {
        rec.field.removeClass(this.errorClass);
      } else {
        rec.field.addClass(this.errorClass);
        valid = false;
        if (!firstInvalidField) {
          firstInvalidField = rec.field;
        }
      }
      this.updateFieldLabel(rec);
    }

    if (firstInvalidField) {
      firstInvalidField.focus();
      firstInvalidField.addClass(this.shakeClass).delay(1000).queue(function () {
        firstInvalidField.removeClass(that.shakeClass).dequeue();
      });
    }

    return valid;
  };

  /**
   * @param {FieldRec} rec
   * @private
   */
  Validator.prototype.updateFieldLabel = function (rec) {
    if (rec.result && rec.result.hasError()) {
      if (!rec.label) {
        rec.label = $('<div></div>').addClass(this.labelClass);
        rec.label.insertBefore(rec.field);
      }
      rec.label.text(rec.result.getErrorMessage());
    }

    var showLabel = rec.result && rec.result.hasError() && rec.focused;
    if (showLabel) {
      var that = this;
      setTimeout(function () {
        rec.label.addClass(that.visibleLabelClass);
      }, 0);
    } else {
      if (rec.label) {
        rec.label.removeClass(this.visibleLabelClass);
      }
    }
  };

  Validator.prototype.clearForm = function () {
    var inputFields = this.fields;
    for (var i = 0; i < inputFields.length; i++) {
      var rec = inputFields[i];
      rec.result = null;
      rec.changed = false;
      this.updateFieldLabel(rec);
    }
    var feedbackFieldValue = $('.form-input-text input, .form-input-text-area textarea');
    feedbackFieldValue.val('');
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /** @var {object} */
  var attachedFiles = {};
  var feedbackSending = false;

  /**
   * @param {string} formId
   * @param {string} name
   * @return jQuery
   */
  function getFormNodeByName(formId, name) {
    return $('#' + formId).find('input[name="' + name + '"],textarea[name="' + name + '"]');
  }

  function getInputValueByName(formId, name) {
    return getFormNodeByName(formId, name).val();
  }

  /**
   * @param {string} formId
   */
  function installValidatorOnForm(formId) {
    var validator = new Validator();

    validator.addField(getFormNodeByName(formId, 'name'), function (value) {
      if (value.length == 0) {
        return ValidationResult.error(trans('main.contact.error.name'));
      }
      return ValidationResult.success();
    });

    validator.addField(getFormNodeByName(formId, 'phone'), function (value) {
      if (value.length == 0) {
        return ValidationResult.error(trans('main.contact.error.phone'));
      }
      return ValidationResult.success();
    });

    validator.addField(getFormNodeByName(formId, 'email'), function (value) {
      if (value.length == 0) {
        return ValidationResult.error(trans('main.contact.error.email1'));
      }

      var EmailRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

      if (!EmailRegEx.test(value)) {
        return ValidationResult.error(trans('main.contact.error.email2'));
      }

      //var invalidDomains = ['gmail\\.com$', 'mail\\.ru$', 'yandex\\.ru$', 'outlook\\.com$', 'live\\.ru$', 'live\\.com$', 'hotmail\\.com$', 'hotmail\\.ru$', 'yahoo\\.com$', 'rambler\\.ru$'];
      var invalidDomains = [];

      for (var i = 0; i < invalidDomains.length; i++) {
        var rx = new RegExp(invalidDomains[i]);
        if (rx.test(value)) {
          return ValidationResult.error(trans('main.contact.error.email3'));
        }
      }

      return ValidationResult.success();
    });

    validator.addField(getFormNodeByName(formId, 'message'), function (value) {
      if (value.length == 0) {
        return ValidationResult.error(trans('main.contact.error.message'));
      }
      return ValidationResult.success();
    });

    return validator;
  }

  /** @typedef {{
   *       name: string,
   *       phone: string,
   *       email: string,
   *       message: string,
   *       success: function(),
   *       error: function(string)
   *   }} MessageParams */

  /**
   * @param {string} formId
   * @param {MessageParams} params
   * */
  function emailMessageWithSebbia(formId, params) {
    var formData = new FormData;
    formData.append('message', params.message);
    formData.append('subject', 'Обращение от ' + params.name);
    formData.append('email', params.email);
    formData.append('phone', params.phone);

    var files = attachedFiles[formId];
    if (files) {
      for (var i = 0; i < files.length; i++) formData.append('file' + i, files[i], files[i].name);
    }

    $.ajax({
      type: 'POST',
      url: '/api/feedback',
      data: formData,
      contentType: false,
      processData: false,
      dataType: 'json',
      success: function (data /*, textStatus*/ ) {
        if (data.code == 0) {
          params.success();
        } else {
          params.error('');
        }
      },
      error: function (xhr, textStatus, errorThrown) {
        params.error(errorThrown);
      }
    });
  }

  function getFilesSize(formId) {
    var files = attachedFiles[formId];
    var size = 0;

    if (files) {
      for (var i = 0; i < files.length; i++) size += files[i].size;
    }
    return Math.floor(size / 1048576);
  }

  function checkFilesSize(size) {
    return (size < Config.get('feedback.interface')['uploadLimit']);
  }

  function updateFilesSize(formId) {
    var $form = $('#' + formId);
    var $limit = $form.find(".upload-limit");
    var $currentLimit = $limit.find(".upload-limit__current");
    var $attachBtn = $form.find('.button-upload');
    if ($attachBtn.length != 1) return;
    var size = getFilesSize(formId);

    $currentLimit.html(size);

    if (size > Config.get('feedback.interface')['uploadLimit']) {
      $limit.addClass('error');
      $attachBtn.prop('disabled', true);
    } else {
      $limit.removeClass('error');
      $attachBtn.prop('disabled', false);
    }
  }

  function removeAttachedFile(e) {
    var $file = $(e.target);
    if (!$file.is('.attached-file')) $file = $file.parents('.attached-file').first();
    if (!$file.is('.attached-file')) return;

    var fileName = $file.data('fileName');
    var formId = $file.data('formId');
    var files = attachedFiles[formId];

    for (var i = 0; i < files.length; i++) {
      if (files[i].name != fileName) continue;
      files.splice(i, 1);
      $file.remove();
      break;
    }
    updateFilesSize(formId);
  }

  function fileSizeToString(size) {
    if (size < 1000) return "1 " + trans('main.contact.fileSize.kb');
    size = size / 1024;
    if (size < 10) return Math.floor(size * 10) / 10 + " " + trans('main.contact.fileSize.kb');
    if (size < 1000) return Math.floor(size) + " " + trans('main.contact.fileSize.kb');
    size = size / 1024;
    if (size < 10) return Math.floor(size * 100) / 100 + " " + trans('main.contact.fileSize.mb');
    return Math.floor(size) + " " + trans('main.contact.fileSize.mb');
  }

  function showAttachedFiles(formId) {
    var $form = $('#' + formId);
    var $attachedFiles = $form.find('.attached-files');
    var files = attachedFiles[formId];
    if ($attachedFiles.length != 1 || !files || files.length == 0) return;
    var filesQty = $attachedFiles.find('.attached-file').length;
    if (filesQty == files.length) return;

    var lastFile = files[files.length - 1];
    var $file = $('<div>').addClass('attached-file animate').append(
      $('<span>').addClass('svg-icon attached-file-delete').html('<svg viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg"><g stroke="none" fill="none" fill-rule="evenodd"><g transform="translate(-1119.000000, -5538.000000)" fill="#D8D8D8" class="svg-animate"><g transform="translate(1119.000000, 5538.000000)"><path d="M10,8.58578644 L2.22182541,0.807611845 L0.807611845,2.22182541 L8.58578644,10 L0.807611845,17.7781746 L2.22182541,19.1923882 L10,11.4142136 L17.7781746,19.1923882 L19.1923882,17.7781746 L11.4142136,10 L19.1923882,2.22182541 L17.7781746,0.807611845 L10,8.58578644 Z"></path></g></g></g></svg>').on('click', removeAttachedFile)
    ).append(
      $('<span>').addClass('attached-file__name').html(lastFile.name)
    ).append(
      $('<span>').addClass('attached-file__size').html('(' + fileSizeToString(lastFile.size) + ')')
    );
    $file.data('fileName', lastFile.name).data('formId', formId);
    $attachedFiles.append($file);
  }

  function onAttach(e) {
    e.preventDefault();
    var $input = $(e.target);
    var formId = $input.data('formId');

    var files = null;
    try {
      if (e['dataTransfer']) files = e['dataTransfer'].files;
      else if (e.target) files = e.target.files;
    } catch (e) {
      return;
    }
    if (!files.length) return;
    if (!attachedFiles[formId]) attachedFiles[formId] = [];
    attachedFiles[formId].push(files[0]);
    showAttachedFiles(formId);
    updateFilesSize(formId);
    $input.val('');
  }

  function initAttach($form) {
    var $attachBtn = $form.find('.button-upload');
    if ($attachBtn.length != 1) return;
    var $input = $attachBtn.find('input[type="file"]');
    if ($input.length != 1) return;

    $input.on('change', onAttach);
    $input.data('formId', $form.attr('id'));
    $attachBtn.on('click', function () {
      $input.get(0).click();
    });
  }

  /**
   * Initialize feedback form with validator and submit action
   *
   * @param {string} formId
   * @param {function()} [onSubmit]
   */
  function initFeedbackForm(formId, onSubmit) {
    var $form = $('#' + formId);
    if ($form.length != 1) return;

    var validator = installValidatorOnForm(formId);
    var inputTag = $form.find(':submit');

    initAttach($form);

    $form.on("submit", function (e) {
      if (feedbackSending) return;
      if (!checkFilesSize(getFilesSize(formId))) return;
      if (!validator.validate()) return;
      inputTag.prop('disabled', true);

      feedbackSending = true;
      /** @type MessageParams */
      var params = {
        name: getInputValueByName(formId, 'name'),
        phone: getInputValueByName(formId, 'phone'),
        email: getInputValueByName(formId, 'email'),
        message: getInputValueByName(formId, 'message'),
        success: function () {
          feedbackSending = false;
          inputTag.prop('disabled', false);
          if (onSubmit) {
            onSubmit();
          }
          if (ga) ga('send', 'event', 'button', 'click', 'ContactUs');
          validator.clearForm();
          showMessageBox('popupFeedbackThanks', null);
        },
        error: function () {
          feedbackSending = false;
          inputTag.prop('disabled', false);
          showMessageBox('popupFeedbackFailed', /*error.toString()*/ '');
        }
      };

      emailMessageWithSebbia($(e.target).attr('id'), params);
      return false;
    });
  }

  window.initFeedbackForm = initFeedbackForm;

})();

$(document).ready(function () {

  initFeedbackForm('feedbackFrom', function () {
    // Actions on successful submit
    /*window.yaCounter29755688.reachGoal('feedback_sent');*/
  })
});
